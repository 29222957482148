import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import { BookingsApi } from '../api/BookingsApi';
import { WidgetConfig } from '../../legacy/types';
import { Resources } from '../types/types';
import { WidgetViewModel } from '../viewModel/viewModel';
import {
  createOnFilterOptionSelectedAction,
  OnFilterOptionSelectedAction,
} from './onFilterOptionSelected/onFilterOptionSelected';
import {
  createOnDialogCloseAction,
  OnDialogCloseAction,
} from './onDialogClose/onDialogClose';
import {
  createHandelNavigationAction,
  NavigationAction,
} from './handleNavigation/handleNavigation';
import { createNavigateToServicePageAction } from './navigateToServicePage/navigateToServicePage';
import { createNavigateToBookPageAction } from './navigateToBookPage/navigateToBookPage';

export type WidgetActions = {
  onFilterOptionSelected: OnFilterOptionSelectedAction;
  onDialogClose: OnDialogCloseAction;
  navigateToServicePage: NavigationAction;
  navigateToBookPage: NavigationAction;
};

export const createWidgetActions = ({
  widgetViewModel,
  filteredResources,
  wixSdkAdapter,
  bookingsApi,
  flowAPI,
  config,
  setProps,
}: {
  widgetViewModel: WidgetViewModel;
  filteredResources: Resources;
  wixSdkAdapter: WixOOISDKAdapter;
  bookingsApi: BookingsApi;
  flowAPI: ControllerFlowAPI;
  config: WidgetConfig;
  setProps: Function;
}): WidgetActions => {
  const onFilterOptionSelected = createOnFilterOptionSelectedAction({
    widgetViewModel,
    filteredResources,
    wixSdkAdapter,
    setProps,
    flowAPI,
  });

  const onDialogClose = createOnDialogCloseAction({
    widgetViewModel,
    setProps,
  });

  const handleNavigation = createHandelNavigationAction({
    widgetViewModel,
    wixSdkAdapter,
    bookingsApi,
    flowAPI,
    config,
    setProps,
  });

  const navigateToServicePage =
    createNavigateToServicePageAction(handleNavigation);
  const navigateToBookPage = createNavigateToBookPageAction(handleNavigation);

  return {
    onFilterOptionSelected,
    onDialogClose,
    navigateToServicePage,
    navigateToBookPage,
  };
};
